* {
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  text-decoration: none;
  color: inherit;
  background-color: transparent;
  border: 0;
  outline: 0;
  text-transform: none;
  --webkit-appearance: inherit;
  box-sizing: border-box;
  min-height: 0vw;
}

body {
  font-family: "Inter", SF Pro Display, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000000;
}

.inline-code {
  font-family: SF Mono, Menlo, Monaco, Consolas, "Courier New", monospace;
  font-weight: 600;
  font-size: 16px;
  border: 1px solid #eeeeee;
  padding: 4px 8px;
  border-radius: 6px;
}
